import React from 'react';

const Description = ({ description }) => {
    const lines = description.split('\n');
    let inList = false;
    let inP = false;
    let inCodeBlock = false;
    let currentItem = [];
    const content = [];
    let listItems = [];
    let pItems = [];
    let codeBlockItems = [];

    const renderTextWithLinksAndCode = (text, index) => {
        const tldList = 'com|net|org|io|jp';
        const urlPattern = new RegExp(`(https?:\\/\\/)?([a-z0-9][a-z0-9\\-]*[a-z0-9]\\.)+(?:${tldList})(\\/[a-z0-9\\.\\-\\$&%/:=#~]*)?`, 'gi');
        const markdownLinkPattern = /\[([^\]]+)\]\((https?:\/\/[^\s\)]+)\)/gi; // マークダウンリンクの正規表現

        const segments = [];
        let lastIndex = 0;
        let match;

        // マークダウンリンクを検出して変換
        while ((match = markdownLinkPattern.exec(text)) !== null) {
            const preText = text.slice(lastIndex, match.index);
            segments.push(...processTextForInlineCode(preText)); // マッチする前のテキストを処理
            lastIndex = match.index + match[0].length; // lastIndexを更新

            // マークダウンリンクをHTMLのリンクに変換
            segments.push(<a href={match[2]} target="_blank" rel="noopener noreferrer" key={`link-${index}-${lastIndex}`}>{match[1]}</a>);
        }

        // URLパターンを検出して変換（マークダウンリンクの処理後のテキストで）
        const remainingTextAfterMarkdownLinks = text.slice(lastIndex);
        let tempIndex = 0; // URL検出用の一時的なインデックス
        while ((match = urlPattern.exec(remainingTextAfterMarkdownLinks)) !== null) {
            const preText = remainingTextAfterMarkdownLinks.slice(tempIndex, match.index);
            segments.push(...processTextForInlineCode(preText, lastIndex + tempIndex)); // lastIndexを基点にした位置調整
            tempIndex = match.index + match[0].length;

            const url = match[0].startsWith('http') ? match[0] : `https://${match[0]}`;
            segments.push(<a href={url} target="_blank" rel="noopener noreferrer" key={`link-${index}-${lastIndex + tempIndex}`}>{match[0]}</a>);
        }

        // 残りのテキスト（URLパターンの処理後）にインラインコードが含まれている場合の処理
        const finalRemainingText = remainingTextAfterMarkdownLinks.slice(tempIndex);
        if (finalRemainingText) {
            segments.push(...processTextForInlineCode(finalRemainingText, lastIndex + tempIndex));
        }

        return segments;
    };


    // テキスト内のインラインコードを処理する関数
    const processTextForInlineCode = (text) => {
        const segments = [];
        let lastIndex = 0;
        let match;
        const codePattern = /`([^`]+)`/g; // インラインコードを検出する正規表現

        while ((match = codePattern.exec(text)) !== null) {
            // インラインコードの前のテキストを追加
            if (match.index > lastIndex) {
                segments.push(text.slice(lastIndex, match.index));
            }

            // インラインコードを`<code>`タグで囲む
            segments.push(<code key={`code-${lastIndex}`}>{match[1]}</code>);

            lastIndex = match.index + match[0].length;
        }

        // インラインコードの後のテキストを追加
        if (lastIndex < text.length) {
            segments.push(text.slice(lastIndex));
        }

        return segments;
    };


    lines.forEach((line, index) => {
        if (line.startsWith('- ')) {
            if (!inList) {
                inList = true;
                listItems = [];
            }

            if (currentItem.length > 0) {
                listItems.push(<li key={`li-${index - 1}`}>{currentItem}</li>);
                currentItem = [];
            }
            currentItem.push(<span key={`span-${index}`}>{line.substring(2)}</span>);
        } else if (line.startsWith("```")) {
            if (!inCodeBlock) {
                inCodeBlock = true;
                codeBlockItems = [];
            } else {
                inCodeBlock = false;
                content.push(<pre key={`pre-${index}`}><code>{codeBlockItems.join('\n')}</code></pre>);
                codeBlockItems = [];
            }
        } else if (inList) {
            if (line.trim() === '') {
                inList = false;
                listItems.push(<li key={`li-${index - 1}`}>{currentItem}</li>);
                content.push(<ul key={`ul-${index}`}>{listItems}</ul>);
                currentItem = [];
            } else {
                const lineContent = renderTextWithLinksAndCode(line, index);
                currentItem.push(<br key={`br-${index}`} />);
                currentItem.push(<span key={`line-${index}`}>{lineContent}</span>);
            }
        } else if (inP) {
            if (line.trim() === '') {
                inP = false;
                content.push(<p key={`p-${index}`}>{pItems}</p>);
                content.push(<br key={`br-${index}`} />);
                currentItem = [];
            } else {
                pItems.push(<br key={`br-${index}`} />)
                const lineContent = renderTextWithLinksAndCode(line, index);
                pItems.push(<span key={`p-${index}`}>{lineContent}</span>);
            }
        } else if (line.startsWith('# ')) {
            content.push(<h1 key={`h1-${index}`}>{line.substring(2)}</h1>);
        } else if (line.startsWith('## ')) {
            content.push(<h2 key={`h2-${index}`}>{line.substring(3)}</h2>);
        } else if (line.startsWith('### ')) {
            content.push(<h3 key={`h3-${index}`}>{line.substring(4)}</h3>);
        } else {
            if (inCodeBlock) {
                codeBlockItems.push(line)
            } else {
                const lineContent = renderTextWithLinksAndCode(line, index);
                if (!inP) {
                    inP = true;
                    pItems = [];
                }

                pItems.push(<span key={`p-${index}`}>{lineContent}</span>);
            }

        }
    }
    );

    if (inList && currentItem.length > 0) {
        listItems.push(<li key={`li-last`}>{currentItem}</li>);
        content.push(<ul key={`ul-last`}>{listItems}</ul>);
    }

    if (inP && pItems.length > 0) {
        content.push(<p key={`p-last`}>{pItems}</p>);
    }

    return <div className='Description'>{content}</div>;
};

export default Description;